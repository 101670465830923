"use client";

import GeneralErrorPage from "@portal/components/pages/generalErrorPage";
import { ErrorInfo } from "react";
import { ErrorBoundary } from "react-error-boundary";

const ErrorBoundaryWrapper = ({ children }: Readonly<{ children: React.ReactNode }>) => (
  <ErrorBoundary
    fallback={<GeneralErrorPage />}
    onError={(error: Error, info: ErrorInfo) => {
      console.error("ErrorBoundary", error, info);
    }}
  >
    {children}
  </ErrorBoundary>
);

export default ErrorBoundaryWrapper;
