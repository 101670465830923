"use client";

import { GetBankColorTheme } from "@portal/helpers/getBankColorTheme";
import useCenterIdOpenPages from "@portal/hooks/useCenterIdOpenPages";

export default function ThemeProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const centerId = useCenterIdOpenPages();
  const theme = GetBankColorTheme(centerId);
  return <div className={`flex w-full ${theme}`}>{children}</div>;
}
